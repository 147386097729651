import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import MetaTags from 'react-meta-tags';

import { APIKey } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { FormatDateJson, ScrollTop } from "../../Utils";
import I18n from '../../Language';
import { LANE } from "../../Enum";
import { getData, setData } from '../../Utils/Storage';

export const Nav = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [MenuHtml, setMenuHtml] = useState([]);
    const [Menu, setMenu] = useState([]);
    const [MetaData, setMetaData] = useState({});

    const [Notify, setNotify] = useState([]);

    useEffect(() => {
        onGetData();
        onGetNotify();
    }, [location]);

    const onGetData = async () => {
        const keyLang = localStorage.getItem("keyLang");
        try {
            let list = [];
            if (Menu.length === 0) {
                const params = {
                    Json: '{"Enabled":"1","Lang":"' + keyLang + '"}',
                    func: "WS_spGetMenu",
                };
                list = await mainAction.CallApiWs(params, dispatch);
                setMenu(list);
                setMetaData(list.find(p => p.Url === location.pathname));
            }
            else {
                list = [...Menu];
            }
            if (list.length > 0) {
                let mainMenu = list.filter(p => p.ParentId === 0);
                let menu = mainMenu.map(item => {
                    let childMenu = list.filter(p => p.ParentId === item.Id);
                    let isActive = (location.pathname === item.Url || childMenu.find(p => p.Url.indexOf(location.pathname) !== -1 && location.pathname !== "/") !== undefined) ? "active" : "";
                    return (
                        (childMenu.length > 0 ? (
                            <li className={"nav-border " + isActive} key={item.Id + "MENU"}>
                                <a href={item.Url} className="dropdown" title={item.Name} data-toggle="dropdown">
                                    {item.Name}
                                    <i className="fa fa-caret-down"></i>
                                </a>
                                {GeneralMenu(item.Id, list)}
                            </li>
                        ) : (
                            <li className={(location.pathname === item.Url ? "nav-border active" : "nav-border")} key={item.Id + "MENU"}>
                                <Link to={item.Url} title={item.Name}>
                                    {item.Name}
                                </Link>
                            </li>
                        ))
                    )
                });
                setMenuHtml(menu);
            }
            ScrollTop();
        } catch (err) {
        }
    };

    const GeneralMenu = (Id, List) => {
        let menuItems = List.filter(p => p.ParentId === Id);
        return (
            <ul className="dropdown-menu">
                {
                    menuItems.map(item => {
                        let childMenu = List.filter(p => p.ParentId === item.Id);
                        return (
                            (childMenu.length > 0 ? (
                                <li className={(location.pathname === item.Url ? " active" : "")} key={item.Id + "MENU"}>
                                    <a href={item.Url} className="dropdown" title={item.Name} data-toggle="dropdown">
                                        {item.Name}
                                        <i className="fa fa-caret-right"></i>
                                    </a>
                                    {GeneralMenu(item.Id, List)}
                                </li>
                            ) : (
                                <li className={(location.pathname === item.Url ? " active" : "")} key={item.Id + "MENU"}>
                                    <Link to={item.Url} title={item.Name}>
                                        {item.Name}
                                    </Link>
                                </li>
                            ))
                        )
                    })
                }
            </ul>
        )
    }

    const onGetNotify = async () => {
        const keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: '{"Lang":"' + keyLang + '"}',
            func: "WS_spGetNotify",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setNotify(list);
        } catch (err) {
        }
    }

    const SEO = (
        <MetaTags>
            <title>{MetaData?.MetaTitle}</title>
            <meta name="description" content={MetaData?.MetaDescription} />
            <meta property="og:image" content={MetaData?.MetaImage} />
            <meta property="og:title" content={MetaData?.MetaTitle} />
            <meta property="og:description" content={MetaData?.MetaDescription} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={MetaData?.MetaTitle} />
        </MetaTags>
    )

    //#region đa ngôn ngữ hệ thống
    const changeLanguage = async (keylang) => {
        let lang = await getData(LANE);
        let params = {
            language: keylang,
            Type: 1
        }
        const language = await mainAction.changeLanguage(params, dispatch);
        await setData(LANE, JSON.stringify(language));
        //#region đa ngôn ngữ leftmenu
        localStorage.setItem("keyLang", keylang);
        window.location.reload();
        //#endregion
    }
    //#endregion

    return (
        <>
            {SEO}
            <nav class="navbar navbar-dark bg-dark navbar-inverse collapse navbar-collapse" id="mainNav">
                <div class="container">
                    <ul class="nav navbar-nav">
                        {MenuHtml}
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        {/* <li class={(location.pathname === "/tra-cuu-don-hang" ? " active" : "")}>
                            <Link to="/tra-cuu-don-hang"><i class="fa fa-search hide-sm"></i> <span class="hide-md">{I18n.t("TopMenu.Lading")}</span></Link>
                        </li> */}
                        {Notify.length === 0 ? (
                            <li>
                                <a href="#" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-bell-o hide-sm"></i> <span class="hide-md">{I18n.t("TopMenu.Notification")}</span></a>
                            </li>
                        ) : (
                            <li>
                                <a href="#" data-toggle="dropdown" class="dropdown"><i class="fa fa-bell-o hide-sm"></i> <span class="hide-md">{I18n.t("TopMenu.Notification")}</span></a>
                                <ul className="dropdown-menu">
                                    <li class="bg-grey80">{I18n.t("TopMenu.Notification")}</li>
                                    {
                                        Notify.map((item, index) => {
                                            return (
                                                <li key={"ntf" + index}>
                                                    <Link to={item.Url}>
                                                        <p class="text white">{item.Title}</p>
                                                        <small class="text-light-green">{FormatDateJson(item.CreateTime, 1)}</small>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                        )}
                        <li class="hide-md">
                            <a onClick={e => changeLanguage("vn")}>{I18n.t("Header.Vietnamese")}  <img src="/assets/img/vn.png" width="30" height="20" alt="NETCO POST" /></a>
                            <a onClick={e => changeLanguage("en")}>{I18n.t("Header.English")}  <img src="/assets/img/en.png" width="30" height="20" alt="NETCO POST" /></a>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* <div class="sidebar ps">
                <div class="sidebar-wrapper ps-container ps-theme-default">
                    <ul class="nav mt0">
                        <li class="border-bottom nav-item active"><a class="nav-link" href="/home"><i class="material-icons mt5">home</i><p> TRANG CHỦ</p></a></li>
                        <li class="border-bottom nav-item "><a class="nav-link" data-toggle="collapse" href="#ladingnav" aria-expanded="false"><i class="material-icons">content_paste</i><p>QUẢN LÝ ĐƠN HÀNG <b class="caret"></b></p></a><div class="collapse" id="ladingnav">
                            <ul class="nav">
                                <li class="nav-item "><a class="nav-link" href="/tao-nhanh-van-don"><i class="material-icons mt5">shopping_cart</i><span class="sidebar-normal"> Tạo nhanh vận đơn</span></a></li>
                                <li class="nav-item "><a class="nav-link" href="/upload-excel-van-don"><i class="material-icons mt5">shopping_cart</i><span class="sidebar-normal"> Upload excel vận đơn</span></a></li>
                                <li class="nav-item "><a class="nav-link" href="/tim-kiem-van-don"><i class="material-icons mt5">search</i><span class="sidebar-normal"> Danh sách vận đơn</span></a></li>
                            </ul>
                        </div>
                        </li>
                    </ul>
                </div>
            </div> */}
        </>
    )
}