import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { Alertwarning, Alertsuccess } from "../Utils";
import { APIKey } from "../Services";
import I18n from '../Language';
import { SelectCity } from ".";
const ContactFormComp = React.forwardRef(() => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const [Name, bindName, setName] = useInput("");
  const [Email, bindEmail, setEmail] = useInput("");
  const [Phone, bindPhone, setPhone] = useInput("");
  const [Content, bindContent, setContent] = useInput("");
  const [LadingCode, bindLadingCode, setLadingCode] = useInput("");

  const [Type, setType] = useState(0);
  const [TypeContact, setTypeContact] = useState("");

  const [TypeComplain, setTypeComplain] = useState(1);

  //  const [AreaId, setAreaId] = useState(0);
  //  const [Area, setArea] = useState(""); 

  const [CityId, setCityId] = useState(0);
  const [CityName, setCityName] = useState("");

  const NameRef = useRef();
  const EmailRef = useRef();
  const PhoneRef = useRef();
  const ContentRef = useRef();
  const LadingCodeRef = useRef();

  const onAction = async () => {
    debugger
    if (Name === "") {
      Alertwarning(I18n.t("ContactForm.RequiredName"));
      NameRef.current.focus();
      return;
    } else if (Email === "") {
      Alertwarning(I18n.t("ContactForm.RequiredEmail"));
      EmailRef.current.focus();
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("ContactForm.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else if (Content === "") {
      Alertwarning(I18n.t("ContactForm.RequiredContent"));
      ContentRef.current.focus();
      return;
    } else if (Type === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredType"));
      return;
    }
    else if (CityId === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredArea"));
      return;
    } else {
      setDisabled(true);
      if (TypeContact === "CSKH") { //Nếu khách chọn khiếu nại dịch vụ
        let params = {
          CustomerId: 0,
          Name: Name,
          Phone: Phone,
          LadingCode: LadingCode,
          Type: parseInt(TypeComplain),
          Content: Content,
          SendFrom: "TỪ Xe Muôn Nơi ",
          CityId:CityId,
          CityName:CityName,
          TypeReceipt:1
        };
        const pr = {
          ApiKey: APIKey,
          Json: JSON.stringify(params),
          func: "APIC_spCustomerComplain_JsonAuto",
          IsLoading: true
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list !== []) {
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ComplainCreate");
          }
          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      }
      else { // Nếu khách chọn tư vấn/báo giá
        const pr = {
          Json: '{"Name":"' + Name + '","Email":"' + Email + '","Phone":"' + Phone + '","ContactContent":"' + Content + '", "Type":"' + Type + '", "TypeName":"' + TypeContact + '", "AreaId":"' + CityId + '", "AreaName": "' + CityName + '","Status":1, "StatusName":"Chưa xử lý"}',
          func: "CRM_spCustomerContactRequest_Save",
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list !== []) {
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ShippingConsulting");
          }

          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      }
    }
  }

  const onReset = () => {
    setName("");
    setPhone("");
    setEmail("");
    setContent("");
  }

  const onSendNofity = async (functionsend) => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: functionsend,
        SendFrom: "WEBSITE NETCO.COM.VN",
        CustomerProvince:CityId,
        JsonData: [
          {
            Name: Name,
            Phone: Phone,
            Content: Content,
          },
        ],
      }),
      func: "APIC_spSendNotification",
      API_key: APIKey,
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
    if (resultNotify !== []) return;
  }

  const onSelectCity = (item) => {
    setCityId(item.value);
    setCityName(item.label);
  }

  return (
    <div class="form" aria-autocomplete="off">
      <div class="form-group">
        <label class="special-label">{I18n.t("ContactPage.InputName")} <span class="text-red">(*)</span></label>
        <input class="form-control" type="text" ref={NameRef} value={Name} {...bindName} maxLength="50" placeholder={I18n.t("ContactForm.InputName")} required />
      </div>
      <div class="form-group">
        <label class="special-label">{I18n.t("ContactPage.InputEmail")} <span class="text-red">(*)</span></label>
        <input class="form-control" type="text" ref={EmailRef} value={Email} {...bindEmail} maxLength="50" placeholder={I18n.t("ContactForm.InputEmail")} required />
      </div>
      <div class="form-group">
        <label class="special-label">{I18n.t("ContactPage.InputPhone")} <span class="text-red">(*)</span></label>
        <input class="form-control" type="text" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
      </div>
      <div class="form-group">
        <label>{I18n.t("ContactForm.SelectYourArea")} <span class="text-red">(*)</span></label>
        <SelectCity key={"CityTo"}
          className={"form-control"}
          Disabled={disabled}
          onActive={CityId}
          onSelected={(item) => onSelectCity(item)}
        />
      </div>
      <div class="form-group">
        <label>{I18n.t("ContactForm.WhatDoYouNeed")} <span class="text-red">(*)</span></label>
        <div class="row">
          <div class="col-md-6">
            <label class="form-check" onClick={(e) => { setTypeContact("TVDV"); setType(4); }}>
              {I18n.t("ContactForm.ShippingConsulting")}
              <input type="radio" name="radio" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-md-6">
            <label class="form-check" onClick={(e) => { setTypeContact("CSKH"); setType(3); }}>
              {I18n.t("ContactForm.CustomerCare")}
              <input type="radio" name="radio" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      {/* <div class="form-group">
        <label>{I18n.t("ContactForm.SelectYourArea")} <span class="text-red">(*)</span></label>
        <div class="row">
          <div class="col-md-4">
            <label class="form-check" onClick={(e) => { setArea("Miền Bắc"); setAreaId(10); }}>
              {I18n.t("ContactForm.North")}
              <input type="radio" name="radioArea" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-md-4">
            <label class="form-check" onClick={(e) => { setArea("Miền Trung"); setAreaId(9); }}>
              {I18n.t("ContactForm.Middle")}
              <input type="radio" name="radioArea" />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-md-4">
            <label class="form-check" onClick={(e) => { setArea("Miền Nam"); setAreaId(11); }}>
              {I18n.t("ContactForm.South")}
              <input type="radio" name="radioArea" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div> */}
      {/* {Type === 3 && (<>
        <div class="form-group">
          <label>{I18n.t("Complain.InputCode")}</label>
          <input class="form-control" type="text" ref={LadingCodeRef} value={LadingCode} {...bindLadingCode} maxLength="50" placeholder={I18n.t("Complain.InputCode")} />
        </div>
        <div class="form-group">
          <label>{I18n.t("Complain.InputType")} <span class="text-red">(*)</span></label>
          <select class="form-control" value={Type} onChange={(e) => setType(e.target.value)}>
            <option value="1">{I18n.t("Complain.ComplainService")}</option>
            <option value="2">{I18n.t("Complain.ComplainPrice")}</option>
            <option value="3">{I18n.t("Complain.ComplainCod")}</option> 
          </select>
        </div>
      </>)} */}
      <div class="form-group">
        <label class="special-label">{I18n.t("ContactPage.InputContent")} <span class="text-red">(*)</span></label>
        <textarea class="form-control" rows="3" ref={ContentRef} value={Content} {...bindContent} placeholder={I18n.t("ContactForm.InputContent")} required></textarea>
      </div>
      <div class="form-group text-right mb20">
        <button type="text" class="btn btn-success pd-30" disabled={disabled} onClick={(e) => onAction()}>{I18n.t("ContactForm.Action")}</button>
      </div>
    </div>
  );
});

export const ContactForm = React.memo(ContactFormComp);
