import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtml from 'raw-html-react';

import { SelectCity, SelectDistrict, SelectWard } from "../../Common";
import I18n from '../../Language';
import { useInput } from "../../Hooks";
import {  Alertwarning } from "../../Utils";

export const Register = () => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [ShowPass, setShowPass] = useState(false);
    const [CityId, setCityId] = useState(0);
    const [DistrictId, setDistrictId] = useState(0);
    const [WardId, setWardId] = useState(0);

    const [CityName, setCityName] = useState("");
    const [DistrictName, setDistrictName] = useState("");
    const [WardName, setWardName] = useState("");

    const [Name,bindName,setName] = useInput("");
    const [Email,bindEmail,setEmail] = useInput("");
    const [Phone,bindPhone,setPhone] = useInput("");
    const [Password,bindPassword,setPassword] = useInput("");
    const [PasswordConfirm,bindPasswordConfirm,setPasswordConfirm] = useInput("");
    const [Address, bindAddress, setAddress] = useInput(0);

    const NameRef = useRef();
    const EmailRef = useRef();
    const PhoneRef = useRef();
    const PasswordRef = useRef();
    const PasswordConfirmRef = useRef();

    const [Step, setStep] = useState(1);
    const [Finish,setFinish] = useState(0);

    useEffect(() => {
        localStorage.setItem("login", "");
    }, []);

    const onSelectCity = (item) => {
        setCityId(item.value);
        setCityName(item.label);
    }
    const onSelectDistrict = (item) => {
        debugger
        setDistrictId(item.value);
        setDistrictName(item.label);
    }

    const onSelectWard = (item) => {
        debugger
        setWardId(item.value);
        setWardName(item.label);
        debugger
    }

    const onNextStep = () =>{
        if(Name==="")
        {
            Alertwarning("Nhập họ & tên");
            return;
        } else if(Email==="")
        {
            Alertwarning("Nhập email");
            return;
        }
        else if(Phone==="")
        {
            Alertwarning("Nhập số điện thoại");
            return;
        }
        else if(Password==="")
        {
            Alertwarning("Nhập mật khẩu");
            return;
        }
        else if(Password!==PasswordConfirm)
        {
            Alertwarning("Nhập lại mật khẩu chưa đúng");
            return;
        }
        setStep(2);
        setFinish(1);
    }

    return (
        <section class="register">
            <div class="row">
                <div class="col-md-6 pd0 hide-xs" style={{ height: "770px", backgroundImage: "url('/assets/img/contact.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    <div class="mask"></div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 col-sm-12 form-pd">
                    <h2>{I18n.t("User.Register")}</h2>
                    <div class="text mb20">{I18n.t("User.DoYouHaveAnAccount")} <Link class="bold" to="/dang-nhap">{I18n.t("User.LoginNow")}</Link></div>
                    <div class={Step === 1 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.FullName')}</label>
                        <input type="text" class="form-control" ref={NameRef} value={Name} {...bindName} placeholder={I18n.t('User.EnterFullName')} />
                    </div>
                    <div class={Step === 1 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.PhoneNumber')}</label>
                        <input type="text" class="form-control" ref={PhoneRef} value={Phone} {...bindPhone} placeholder={I18n.t('User.EnterPhoneNumber')} />
                    </div>
                    <div class={Step === 1 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.Email')}</label>
                        <input type="text" class="form-control" ref={EmailRef} value={Email} {...bindEmail} placeholder={I18n.t('User.EnterEmail')} />
                    </div>
                    <div class={Step === 1 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.Password')}</label>
                        <div class="input-group">
                            <input type={ShowPass ? "text" : "password"} class="form-control" ref={PasswordRef} value={Password} {...bindPassword} placeholder={I18n.t('User.EnterYourPassword')} />
                            <div class="input-group-append">
                                <button class="btn btn-default" type="button" onClick={(e) => { setShowPass(!ShowPass) }}>
                                    <i class={ShowPass ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class={Step === 1 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.RePassWord')}</label>
                        <div class="input-group">
                            <input type={ShowPass ? "text" : "password"} class="form-control" ref={PasswordConfirmRef} value={PasswordConfirm} {...bindPasswordConfirm} placeholder={I18n.t('User.RePassWord')} />
                            <div class="input-group-append">
                                <button class="btn btn-default" type="button" onClick={(e) => { setShowPass(!ShowPass) }}>
                                    <i class={ShowPass ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class={Step === 2 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.City')}</label>
                        <SelectCity key={"City"}
                            className={"form-control"}
                            Disabled={disabled}
                            onActive={CityId}
                            onSelected={(item) => onSelectCity(item)}
                        />
                    </div>
                    <div class={Step === 2 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.District')}</label>
                        <SelectDistrict
                            className={"form-control"}
                            onActive={DistrictId}
                            ParentID={CityId}
                            onSelected={(item) => {
                                onSelectDistrict(item);
                            }}
                        />
                    </div>
                    <div class={Step === 2 ? "form-group" : "form-group display-none"}>
                        
                        <label class="frm-label">{I18n.t('User.Ward')}</label>
                        <SelectWard
                            className={"form-control"}
                            onActive={WardId}
                            ParentID={DistrictId}
                            onSelected={(item) => {
                                onSelectWard(item);
                            }}
                        />
                    </div>
                    <div class={Step === 2 ? "form-group" : "form-group display-none"}>
                        <label class="frm-label">{I18n.t('User.Address')}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder={I18n.t('User.Address')} />
                            <div class="input-group-append">
                                <span class="input-group-text">{WardName + ", " + DistrictName + ", " + CityName}</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt20 mb20">
                        <span class={Step === 1 ? "step active": (Finish===1?"step finish":"step")} onClick={(e) => setStep(1)}></span>
                        <span class={Step === 2 ? "step active" : "step"} onClick={(e) => onNextStep()}></span>
                    </div>
                    <div class="form-group">
                        <button type="button" class={Step === 1 ? "btn btn-success w-100":"display-none"} onClick={(e)=> onNextStep()}>{I18n.t('User.Next')}</button>
                        <button type="button" class={Step === 2 ? "btn btn-success w-100":"display-none"}>{I18n.t('User.Register')}</button>
                    </div>
                    {/* <div class="form-group text-sm">
                        <ReactHtml html={I18n.t('User.RegisterRules')} componentMap={Register} />
                    </div> */}
                </div>
                <div class="col-md-2"></div>
            </div>
        </section>
    )
}