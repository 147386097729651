import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { mainAction } from "../Redux/Actions";
import { APIKeySystem } from "../Services/Api";
const SelectWardComp = React.forwardRef(({
  onSelected = () => { },
  onActive = 0,
  ParentID = 0,
  Disabled = false
}, ref) => {
  const [data, setData] = useState([]);
  const [valueS, setValueS] = useState({});
  const [_default, setDefault] = useState({ value: 0, label: "Chọn phường xã" });
  const onSelecteItem = (item) => {
    onSelected(item);
    setValueS(item);
  };

  const dispatch = useDispatch();
  const onGetLocation = async () => {
    if (ParentID === 0 || ParentID === undefined) return;
    const params = {
      Json: "{\"ParentID\":"+ParentID+",\"Type\":3}",
      func: "CPN_spLocation_GET",
    };
    const list = await mainAction.API_spCallServerSystem(params, dispatch);

    let dataOptions = [];
    dataOptions.push(_default);
    list.forEach((element, index) => {
      let option = { value: element.LocationId, label: element.Name };
      dataOptions.push(option);
    });
    setData(dataOptions);
    if (onActive !== 0) {
      let dataActive = dataOptions.find(a => a.value === onActive);
      setValueS(dataActive);
    }else setValueS(_default);
  };

  useEffect(() => {
    setDefault({ value: 0, label: "Chọn phường xã" });
  }, []);

  useEffect(() => {
    onGetLocation();
  }, [onActive,ParentID]);
  return <Select value={valueS} onChange={onSelecteItem} options={data} isDisabled={Disabled} ref={ref} />;
});

export const SelectWard = React.memo(SelectWardComp);
