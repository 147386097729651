import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import MetaTags from 'react-meta-tags';

import { APIKey, FILE_URL, CUSTOMER_CREATE_LADING_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop, FormatDateJson } from "../../Utils";
import I18n from '../../Language';

export const NewsDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [Info, setInfo] = useState({});
    const [List, setList] = useState([]);

    const [ParentUrl, setParentUrl] = useState("");
    const [ListType, setListType] = useState("");
    const [AnotherType, setAnotherType] = useState("");

    useEffect(() => {
        if (location.pathname === "/chi-tiet-khuyen-mai") {
            setParentUrl("khuyen-mai");
            setListType(I18n.t("News.TitlePromotion"));
            setAnotherType(I18n.t("News.AnotherPromotion"));
        }
        else if (location.pathname === "/chi-tiet-hoat-dong") {
            setParentUrl("hoat-dong");
            setListType(I18n.t("News.TitleActivate"));
            setAnotherType(I18n.t("News.AnotherActivate"));
        }
        else if (location.pathname === "/chi-tiet-huong-dan") {
            setParentUrl("huong-dan");
            setListType(I18n.t("News.TitleTutorial"));
            setAnotherType(I18n.t("News.AnotherTutorial"));
        }
        else {
            setParentUrl("khuyen-mai");
            setListType(I18n.t("News.TitlePromotion"));
            setAnotherType(I18n.t("News.AnotherPromotion"));
        }

        const pr = new URLSearchParams(location.search);
        if (pr.get("title") !== "") {
            onGetData(pr.get("title"));
        }
        else
            history.push('/hoat-dong');
    }, [location]);

    const onGetData = async (url) => {
        const params = {
            Json: '{"Url":"' + url + '"}',
            func: "WS_spGetNews_Detail",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            let info = list[0];
            setInfo(info);
            onGetList(info.TypeNews, url);
            ScrollTop();
        } catch (err) {
        }
    };

    const onGetList = async (type, url) => {
        const params = {
            Json: '{"TypeNews":"' + type + '","Take":"6"}',
            func: "WS_spGetNews",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setList(list.filter(p => p.Url !== url));
            ScrollTop();
        } catch (err) {
        }
    };

    const SEO = (
        <MetaTags>
            <title>{Info?.MetaTitle}</title>
            <meta name="description" content={Info?.MetaDescription} />
            <meta property="og:image" content={Info?.ImgFull} />
            <meta property="og:title" content={Info?.MetaTitle} />
            <meta property="og:description" content={Info?.MetaDescription} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={Info?.MetaTitle} />
        </MetaTags>
    )

    return (
        <section class="content">
            {SEO}
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span><Link to={ParentUrl}>{ListType}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{Info?.Name}</span>
                </div>
                <div class="row">
                    <div class="col-md-8 text-justify">
                        <h1 class="h3 mb10">{Info.Name}</h1>
                        <ReactHtml html={Info.Content?.replaceAll("src=\"/image/", "src=\"" + FILE_URL + "image/")?.replaceAll("src=\"/Image/", "src=\"" + FILE_URL + "image/")} componentMap={{ NewsDetail }} />
                    </div>
                    <div class="col-md-4 sidebar-right">
                        <h5 class="mt20 mb20 bold">{AnotherType}</h5>
                        <div class="row">
                            {
                                List.map((item, index) => {
                                    return (
                                        <div class="col-md-12" key={"news3" + index}>
                                            <div class="box-news-right">
                                                <Link to={"/chi-tiet-" + (Info.Type === 1 ? "hoat-dong" : (Info.Type === 2 ? "khuyen-mai" : "huong-dan")) + "?title=" + item.Url}>
                                                    <img class="img-thumb" src={FILE_URL + item.ImgThumb} />
                                                    <div class="item-right">
                                                        <div class="text-sm">{item.Name}</div>
                                                        <div class="text-xs"><i class="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div class="col-md-12 box-share mb10">
                                <div class="text-lg-bolder mb10 mt20">
                                    {I18n.t("News.ShareToSocial")}
                                </div>
                                <a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/fb-square.png" alt="Share on Facebook" />
                                </a>
                                <a target="_blank" rel="noreferrer" href={"https://plus.google.com/share?url=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/gg-square.png" alt="Share on Google+" />
                                </a>
                                <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?original_referer=" + window.location.href + "%2F&amp;source=tweetbutton&amp;text=" + Info.Name + "&amp;url=" + window.location.href + "%2F&amp;via="} onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/tw-square.png" alt="Share on Twitter" />
                                </a>
                            </div>
                            {/* <div class="col-md-12">
                                <a target="_blank" rel="noreferrer" href={CUSTOMER_CREATE_LADING_URL} class="btn btn-success w-100 white">{I18n.t("Banner.CreateLading")}</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}