import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useInput } from "../../Hooks";
import { APIKeySystem } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop, FormatNumber, FormatDateJson, Alertwarning, Alertinfo } from "../../Utils";
import I18n from '../../Language';

export const Lading = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const _history = useHistory();
    const location = useLocation();

    const [Info, setInfo] = useState({});
    const [History, setHistory] = useState([]);
    const [Products, setProduct] = useState([]);
    const [AnotherLading, setAnotherLading] = useState([]);

    const [Code, bindCode, setCode] = useInput("");
    const CodeRef = useRef();

    const [DOCode, bindDOCode, setDOCode] = useInput("");
    const DOCodeRef = useRef();

    const [IsShow, setIsShow] = useState(false);
    const [IsShowImage, setIsShowImage] = useState(false);

    const [CustomerPhone, bindCustomerPhone, setCustomerPhone] = useInput("");
    const CustomerPhoneRef = useRef();
    const [CustomerCode, bindCustomerCode, setCustomerCode] = useInput("");
    const CustomerCodeRef = useRef();

    useEffect(() => {
        const pr = new URLSearchParams(location.search);
        if (pr.get("code") !== null)
            setCode(pr.get("code"));
        else
            setCode("");
        if (pr.get("docode") !== null)
            setDOCode(pr.get("docode"));
        else
            setDOCode("");
        onGetDetail(pr.get("code"), pr.get("docode"));
    }, [location]);

    const onAction = () => {
        if (Code !== "" || DOCode !== "")
            _history.push("/tra-cuu-don-hang?code=" + Code + "&docode=" + DOCode)
        else {
            Alertwarning(I18n.t("Lading.ValidateCode"));
            setIsShow(false);
            setInfo({});
            setHistory([]);
            setAnotherLading([]);
            setIsShowImage(false);
            ScrollTop();
        }
    }

    const onGetDetail = async (code, docode) => {
        if (code !== "") {
            setDisabled(true);
            const params = {
                Json: '{\"Code\":\"' + code.trim() + '\"}',
                func: "APIC_spLading_Find",
            };

            try {
                const list = await mainAction.API_spCallServerSystem(params, dispatch);
                if (list.Detail !== undefined) {
                    setIsShow(true);
                    setInfo(list.Detail[0]);
                    setHistory(list.History);
                    setProduct(list.Products);
                    setAnotherLading([]);
                }
                else {
                    setIsShow(false);
                    setInfo({});
                    setHistory([]);
                    setProduct([]);
                    setAnotherLading([]);
                }
                setIsShowImage(false);
                ScrollTop();
                setDisabled(false);
            } catch (err) {
                setDisabled(false);
            }
        } else if (docode !== "") {
            setDisabled(true);
            const params = {
                Json: '{\"Code\":\"' + docode.trim() + '\"}',
                func: "APIC_spLading_FindDOCode",
                IsLoading: true
            };

            try {
                Alertinfo("Đang truy xuất dữ liệu ...");
                const list = await mainAction.API_spCallServerSystem(params, dispatch);
                if (list.Detail !== undefined) {
                    setIsShow(true);
                    setInfo(list.Detail[0]);
                    setHistory(list.History);
                    setAnotherLading(list.Detail.filter(p => p.Id !== list.Detail[0].Id));
                }
                else {
                    setIsShow(false);
                    setInfo({});
                    setHistory([]);
                    setAnotherLading([]);
                }
                setIsShowImage(false);
                ScrollTop();
                setDisabled(false);
            } catch (err) {
                setDisabled(false);
            }
        }
    };

    const onCheckShowImage = () => {
        debugger
        if (CustomerCode !== "" && CustomerCode.toUpperCase() === Info?.CustomerCode.toUpperCase())
            setIsShowImage(true);
        else if (CustomerPhone !== "" && CustomerPhone === Info?.CustomerPhone)
            setIsShowImage(true);
        else
            setIsShowImage(false);
    }

    const HtmlLading = (
        <>
            <div class="row box-ld mt20">
                <div class="col-md-12">
                    <h2 class="h5 mb10"><span class="bolder">{I18n.t("Lading.CustomerCareLine")}</span></h2>
                    <div class="row">
                        <div class="col-md-4">
                            <i class="fa fa-user-o mr10"></i> {Info?.OfficerNameCS}
                        </div>
                        <div class="col-md-4">
                            <i class="fa fa-phone mr10"></i> {Info?.PhoneCS}
                        </div>
                        <div class="col-md-4">
                            <i class="fa fa-envelope-o mr10"></i> {Info?.EmailCS}
                        </div>
                    </div>
                </div>
            </div>

            {
                AnotherLading.length > 0 ? (
                    <div class="row box-ld mt20">
                        <div class="col-md-12">
                            <h2 class="h5 mb10"><span class="bolder">{I18n.t("Lading.LadingSameDOCode")}</span></h2>
                            <ul>
                                {
                                    AnotherLading.map(
                                        (item, index) => {
                                            return (
                                                <li key={"ladingcode" + index}>
                                                    <Link class="label label-success" to={"/tra-cuu-don-hang?code=" + item.Code}><i class="fa fa-eye mr10"></i> {item.Code}</Link>
                                                </li>
                                            );
                                        })
                                }
                            </ul>
                        </div>
                    </div>
                ) : (<></>)
            }


            <div class="row box-ld mt20">
                <div class="col-md-12 mb10">
                    <h2 class="h5">
                        <span class="bolder">{I18n.t("Lading.LadingInformation")}: </span>
                        <span class="text-green text-lg-bolder">{Info?.Code}</span>
                        <div class="pull-right text-lg-bolder">
                            {I18n.t("Lading.Status")}: <span class="text-green">{Info?.StatusName}</span>
                        </div>
                    </h2>
                </div>
                <div class="col-md-4">
                    <div class="green1">
                        <p><b>{I18n.t('Lading.CreateDate')}:</b> <span class="text">{FormatDateJson(Info?.CreateDate, 1)}</span></p>
                        <p>
                            <b>{I18n.t('Lading.DealineTime')}:</b> <span class="text">{I18n.t("Lading.EstimateTime")} {FormatDateJson(Info?.DealineTime, 1)}</span>
                        </p>

                        {Info?.FinishDate !== undefined && <p><b>{I18n.t('Lading.FinishDate')}:</b> <span class="text-bold text-green">{FormatDateJson(Info?.FinishDate, 1)}</span></p>}
                        {Info?.Recipient_reality !== undefined && <p><b>{I18n.t('Lading.RecipientReality')}:</b> <span class="text-bold text-green">{Info?.Recipient_reality}</span></p>}

                        <p><b>{I18n.t('Lading.ServiceName')}:</b> <span class="text">{Info?.ServiceName}</span></p>
                        <p><b>{I18n.t('Lading.Weight')}:</b> <span class="text">{FormatNumber(Info?.Weight)} gram</span></p>
                        <p><b>{I18n.t('Lading.Number')}:</b> <span class="text">{FormatNumber(Info?.Number)}</span></p>
                        {Info?.Mass !== 0 && <p><b>{I18n.t('Lading.Mass')}:</b> <span class="text">{FormatNumber(Info?.Mass)}</span></p>}
                        <p><b>{I18n.t('Lading.PaymentString')}:</b> <span class="text">{Info?.PaymentString}</span></p>
                        {Info?.Description !== "" && <p><b>{I18n.t('Lading.Description')}:</b> <span class="text">{Info?.Description}</span></p>}
                        {Info?.Noted !== "" && <p><b>{I18n.t('Lading.Noted')}:</b> <span class="text">{Info?.Noted}</span></p>}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="green1">
                        {/* <h2 class="h5">{I18n.t("Lading.SenderInformation")}</h2> */}
                        <p><b>{I18n.t('Lading.PartnerCode')}:</b> <span class="text">{Info?.PartnerCode}</span></p>
                        <p><b>{I18n.t('Lading.CustomerName')}:</b> <span class="text">{Info?.CustomerName}</span></p>
                        <p><b>{I18n.t('Lading.CitySendCode')}:</b> <span class="text">{Info?.CitySendCode}</span></p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="green1">
                        {/* <h2 class="h5">{I18n.t("Lading.RecipientInformation")}</h2> */}
                        <p><b>{I18n.t('Lading.RecipientName')}:</b> <span class="text">{Info?.RecipientName}</span></p>
                        <p><b>{I18n.t('Lading.RecipientPhone')}:</b> <span class="text">{Info?.RecipientPhone}</span></p>
                        <p><b>{I18n.t('Lading.RecipientAddress')}:</b> <span class="text">{Info?.RecipientAddress}</span></p>
                        <p><b>{I18n.t('Lading.CityRecipientCode')}:</b> <span class="text">{Info?.CityRecipientCode}</span></p>
                    </div>
                </div>
            </div>
            <div class="row box-ld mt20">
                <div class="col-md-12 mb10">
                    <h2 class="h5">
                        <span class="bolder">{I18n.t("Lading.Products")}</span>
                    </h2>
                </div>
                <div class="col-md-12">
                    <table id="dataTable" className="table table-primary">
                        <thead>
                            <tr>
                                <th width="150">{I18n.t("Lading.ProductCode")}</th>
                                <th>{I18n.t("Lading.ProductName")}</th>
                                <th>{I18n.t("Lading.Quanlity")}</th>
                                <th>{I18n.t("Lading.ProductDescription")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Products.map((item, index) => {
                                return (
                                    <tr key={index} className={'line' + index}>
                                        <td>{item.ProductCode}</td>
                                        <td>{item.ProductName}</td>
                                        <td>{item.Quanlity}</td>
                                        <td>{item.ProductDescription}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row box-ld mt20">
                <div class="col-md-12 mb10">
                    <h2 class="h5">
                        <span class="bolder">{I18n.t("Lading.HistoryLading")}</span>
                        <div class="pull-right">
                            <span class="text-lg"><a href="https://customer.netco.com.vn" class="text-blue" target="_blank" title={I18n.t("Lading.ImageDelivery")}><img width="25" src="/assets/img/icon24/map.png" alt="map" /> {I18n.t("Lading.FollowLading")}</a></span>
                        </div>
                    </h2>
                </div>
                <div class="col-md-12">
                    <table id="dataTable" className="table table-primary">
                        <thead>
                            <tr>
                                <th width="150">{I18n.t("Lading.DateTime")}</th>
                                <th>{I18n.t("Lading.Position")}</th>
                                <th>{I18n.t("Lading.Status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {History.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{FormatDateJson(item.DateTime)}</td>
                                        <td>{item.POName}</td>
                                        <td>{item.Note}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row box-ld mt20">
                <div class="col-md-12 mb10">
                    <h2 class="h5">
                        <span class="bolder">{I18n.t("Lading.ImageDelivery")}</span>
                    </h2>
                </div>
                <div class="col-md-12">
                    <p>{I18n.t("Lading.Note")}</p>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label class="bold">{I18n.t("Lading.CustomerCode")}</label>
                                <input type="text" ref={CustomerCodeRef} value={CustomerCode} {...bindCustomerCode} class="form-control" placeholder={I18n.t("Lading.CustomerCode")} />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label class="bold">{I18n.t("Lading.CustomerPhone")}</label>
                                <input type="text" ref={CustomerPhoneRef} value={CustomerPhone} {...bindCustomerPhone} class="form-control" placeholder={I18n.t("Lading.CustomerPhone")} />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-success mt25 w-100" onClick={(e) => onCheckShowImage()}>{I18n.t("Lading.Confirm")}</button>
                        </div>
                    </div>
                    {IsShowImage ? (
                        <div class="row">
                            <div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>
                                <b className="colortext">{I18n.t("Lading.DeliveryImage")} </b><br />
                                {Info.ImageDeliveryList !== undefined &&
                                    Info.ImageDeliveryList !== "" &&
                                    Info.ImageDeliveryList !== null
                                    ? JSON.parse(Info.ImageDeliveryList).map(
                                        (img, index) => {
                                            return (
                                                <img src={img.splitdata} style={{ width: "100%", display: "block" }} />
                                            );
                                        }
                                    )
                                    : null}
                            </div>
                            <div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>
                                <b className="colortext">{I18n.t("Lading.SetUpImage")} </b><br />
                                {Info.SetUpImage !== undefined &&
                                    Info.SetUpImage !== "" &&
                                    Info.SetUpImage !== null
                                    ? JSON.parse(Info.SetUpImage).map(
                                        (img, index) => {
                                            return (
                                                <img src={img.splitdata} style={{ width: "100%", display: "block" }} />
                                            );
                                        }
                                    )
                                    : null}
                            </div>
                            <div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>
                                <b className="colortext">{I18n.t("Lading.ImageBP")} </b><br />
                                {Info.ImageBP !== undefined &&
                                    Info.ImageBP !== "" &&
                                    Info.ImageBP !== null
                                    ? JSON.parse(Info.ImageBP).map(
                                        (img, index) => {
                                            return (
                                                <img src={img.splitdata} style={{ width: "100%", display: "block" }} />
                                            );
                                        }
                                    )
                                    : null}
                            </div>
                            <div className="col-md-12" style={{ width: "100%", marginTop: "30px" }}>
                                <b className="colortext">{I18n.t("Lading.ImageTHBB")} </b><br />
                                {Info.ImageTHBB !== undefined &&
                                    Info.ImageTHBB !== "" &&
                                    Info.ImageTHBB !== null
                                    ? JSON.parse(Info.ImageTHBB).map(
                                        (img, index) => {
                                            return (
                                                <img src={img.splitdata} style={{ width: "100%", display: "block" }} />
                                            );
                                        }
                                    )
                                    : null}
                            </div>
                        </div>
                    ) : (<></>)}
                </div>
            </div>
        </>
    )

    return (
        <section class="content">
            {/* <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("Lading.Title")}</span>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3>{I18n.t('Lading.Title')}</h3>
                    </div>
                </div>
                <div class="row box-ld">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label class="bold">{I18n.t('Lading.Code')}</label>
                            <input type="text" ref={CodeRef} value={Code} {...bindCode} class="form-control" placeholder={I18n.t('Banner.InputLadingCode')} />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group">
                            <label class="bold">{I18n.t('Lading.DOCode')}</label>
                            <input type="text" ref={DOCodeRef} value={DOCode} {...bindDOCode} class="form-control" placeholder={I18n.t('Lading.EnterDOCode')} />
                        </div>
                    </div>
                    <div class="col-md-2"><button type="submit" disabled={disabled} class="btn btn-success w-100 mt25" onClick={(e) => { onAction() }}>{I18n.t('Banner.Search')}</button></div>
                    <div class="clearfix"></div>
                    <p style={{ textAlign: "center" }}>{I18n.t("Lading.ContactCEO")}</p>
                    {!IsShow ? <div style={{ textAlign: "center", fontSize: "20px" }}>Không tìm thấy vận đơn</div> : <></>}
                </div>
                {!IsShow ? <></> : HtmlLading}
            </div> */}
        </section>
    )
}