import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { TrackingPrice } from "../../Common";
import I18n from '../../Language';

export const PriceTracking = () => {
    const dispatch = useDispatch();

    return (
        <section class="content">
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("Banner.CreateLading")} / </span> <span>{I18n.t("Banner.Shipper")}</span>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3>{I18n.t('Banner.CreateLading')} /  {I18n.t("Banner.Shipper")}</h3>
                    </div>
                </div>
                <div class="row box-ld box-price">
                    <div class="col-md-12">
                        <TrackingPrice />
                    </div>
                </div>
            </div>
        </section>
    )
}