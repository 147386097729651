import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { Alertwarning, Alertsuccess, FormatMoney, FormatDateJson } from "../Utils";
import { APIKey, API_WS } from "../Services";
import I18n from '../Language';
import { SelectCity, SelectDistrict } from "./";
import DateTimePicker from 'react-datetime-picker';


const TrackingPriceComp = ({

}, ref) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [ShowPrice, setShowPrice] = useState(0);
    const [Weight, bindweight, setWeight] = useInput(0);
    const [COD, , bindCOD, setCOD] = useInput(0);
    const [Insured, bindInsured, setInsured] = useInput(0);
    const [Length, bindLength, setLength] = useInput(0);
    const [Width, bindWidth, setWidth] = useInput(0);
    const [Height, bindHeight, setHeight] = useInput(0);
    const [CityFromId, setCityFromId] = useState(0);
    const [DistrictFromId, setDistrictFromId] = useState(0);
    const [CityToId, setCityToId] = useState(0);
    const [DistrictToId, setDistrictToId] = useState(0);
    const [ListPrice, setListPrice] = useState(0);
    const [KeyAbsolute, setKeyAbsolute] = useState(0);
    const [startDate, setStartDate] = useState(new Date());

    const CODRef = useRef();
    const InsuredRef = useRef();
    const WeightRef = useRef();
    const LengthRef = useRef();
    const WidthRef = useRef();
    const HeightRef = useRef();

    const onSelectCityFrom = (item) => {
        setCityFromId(item.value);
    }
    const onSelectDistictFrom = (item) => {
        setDistrictFromId(item.value);
    }
    const onSelectCityTo = (item) => {
        setCityToId(item.value);
    }
    const onSelectDistrictTo = (item) => {
        setDistrictToId(item.value);
    }


    const [Ways, setWays] = useState(1);
    const [Airport, setAirport] = useState(0);
    const [Phone,setPhone] = useState();
    const [Name,setName] = useState('');
    const spCallCar_Save = async () => {

        debugger
        if (CityFromId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityFromId"));
            return;
        } else if (CityToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityToId"));
            return;
        }
        


        else if (DistrictToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredDistrictToId"));
            return;
        }
        // else if (Weight === 0) {
        //     Alertwarning(I18n.t("TrackingPrice.RequiredWeight"));
        //     WeightRef.current.focus();
        //     return;
        // }

        let pr = {
            OrderCarCode: 0,
            OrderCarName: '',
            FromWhere: CityFromId,
            ToWhere: CityToId,
            DistrictFrom: DistrictFromId,
            DistrictTo: DistrictToId,
            Phone:Phone,
            Name : Name,
            GoTime: startDate,
            Drive: Ways,
            Airport: Airport,
            Shipper: KeyAbsolute,
            Weight: Weight

        };
        const params = {
            ApiKey: APIKey,
            Json: JSON.stringify(pr),
            func: "spCallCar_Save",
            IsLoading: true
        }
        let respone = await mainAction.CallApiWs(params, dispatch);
        Alertsuccess(respone.Result);
        
        setCityFromId(0);
        setCityToId(0);
        setDistrictFromId(0);
        setDistrictToId(0);
        setPhone(0);
        setWeight(0);
        setStartDate(0);
        setKeyAbsolute(0);
        setName(0);
        
      
    }

    const onTrackingPrice = async () => {
        if (CityFromId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityFromId"));
            return;
        } else if (CityToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredCityToId"));
            return;
        }
        else if (DistrictToId === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredDistrictToId"));
            return;
        }
        else if (Weight === 0) {
            Alertwarning(I18n.t("TrackingPrice.RequiredWeight"));
            WeightRef.current.focus();
            return;
        }

        let pr = {
            CustomerId: 0,
            CityGoId: CityFromId,
            CityToId: CityToId,
            DistrictTo: DistrictToId,
            WardToId: 0,
            Weight: Weight,
            Mass: 0,
            Number: 0,
            HHKG: Insured,
            COD: COD
        };
        const params = {
            ApiKey: APIKey,
            Json: JSON.stringify(pr),
            func: "CPN_spLading_EstimatesPrice",
            IsLoading: true
        };

        try {
            setDisabled(true);
            const list = await mainAction.API_spCallServerSystem(params, dispatch);
            setListPrice(list.filter(p => p.ServiceName !== "Xe Muon Noi" && p.ServiceName !== "Phát hỏa tốc"));
            setShowPrice(1);
            setDisabled(false);
        } catch (err) {
            setDisabled(false);
        }
    }

    return (
        <div class="row">
            {/* <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="frm-label">{I18n.t('TrackingPrice.ShippingRange')}</label>
                                        <select class="form-control">
                                            <option value="0">{I18n.t('TrackingPrice.Domestic')}</option>
                                            <option value="1">{I18n.t('TrackingPrice.International')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="frm-label">{I18n.t('TrackingPrice.NumberPacked')}</label>
                                        <input type="text" class="form-control" placeholder={I18n.t('TrackingPrice.InputNumberPacked')} />
                                    </div>
                                </div> */}
            <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.CityFrom')} <span class="required">*</span></label>
                    <SelectCity key={"CityFrom"}
                        className={"form-control"}
                        Disabled={disabled}
                        onActive={CityFromId}
                        onSelected={(item) => onSelectCityFrom(item)}
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.DistrictFrom')}</label>
                    <SelectDistrict
                        className={"form-control"}
                        onActive={DistrictFromId}
                        ParentID={CityFromId}
                        onSelected={(item) => {
                            onSelectDistictFrom(item);
                        }}
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.CityTo')} <span class="required">*</span></label>
                    <SelectCity key={"CityTo"}
                        className={"form-control"}
                        Disabled={disabled}
                        onActive={CityToId}
                        onSelected={(item) => onSelectCityTo(item)}
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.DistrictTo')} <span class="required">*</span></label>
                    <SelectDistrict
                        className={"form-control"}
                        onActive={DistrictToId}
                        ParentID={CityToId}
                        onSelected={(item) => {
                            onSelectDistrictTo(item);
                        }}
                    />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    {/* <label class="frm-label">{I18n.t('TrackingPrice.Weight')} (Gram) <span class="required">*</span></label>
                    <input type="number" class="form-control " ref={WeightRef} required value={Weight} {...bindweight} placeholder={I18n.t('TrackingPrice.InputWeight')} /> */}

                    <label class="frm-label">{I18n.t('TrackingPrice.Weight')} (Gram) <span class="required">*</span></label>
                    <input type="number" class={KeyAbsolute !== 0 ? "form-control" : " form-control display-none"} ref={WeightRef} required value={Weight} {...bindweight} placeholder={I18n.t('TrackingPrice.InputWeight')} />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                     <label class="frm-label">{I18n.t('TrackingPrice.Date')} <span class="required">*</span></label> 
                   {/*<input type="datetime" class="form-control" /> */}
                    {/* <DatePicker selected={startDate} onChange={(e) => setStartDate(e.target.value)} /> */}
                    <DateTimePicker onChange={setStartDate} value={startDate} />
                </div>
            </div>

            {/* <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.COD')} (VNĐ)</label>
                    <input type="number" max="9999999999" class="form-control" ref={CODRef} value={COD} {...bindCOD} placeholder={I18n.t('TrackingPrice.InputCOD')} />
                </div>
            </div>*/}


   <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.InputPhone')} </label>
                    <input type="text"maxLength={10} class="form-control"  value={Phone} 
                    onChange = {(e)=>setPhone(e.target.value)}
                    placeholder={I18n.t('TrackingPrice.InputPhone')} />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label class="frm-label">{I18n.t('TrackingPrice.InputName')} <span class="required">*</span> </label>
                    <input type="text"maxLength={50} class="form-control"  value={Name} 
                    onChange = {(e)=>setName(e.target.value)}
                    placeholder={I18n.t('TrackingPrice.InputName')} />
                </div>  
            </div>

         

            {/* Đi liên tỉnh*/}
            <div class="col-md-6">
                {/* <label class="frm-label">{I18n.t('TrackingPrice.Size')} ({I18n.t('TrackingPrice.Length')} x {I18n.t('TrackingPrice.Width')} x {I18n.t('TrackingPrice.Height')})  (cm)</label> */}
                <label class="frm-label">{I18n.t('TrackingPrice.Drive')} </label>
                <div class="row">
                    <div class="col-xs-4 ">
                        <div class="form-group ">
                            {/* <input type="number" class="form-control" ref={LengthRef} value={Length} {...bindLength} placeholder={I18n.t('TrackingPrice.Length')} /> */}
                            <input type="radio" name="ways"  onClick={(e) => setWays(1)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Oneway')} </label>
                        </div>
                    </div>
                    <div class="col-xs-4 ">
                        <div class="form-group ">
                            {/* <input type="number" class="form-control" ref={WidthRef} value={Width} {...bindWidth} placeholder={I18n.t('TrackingPrice.Width')} /> */}
                            <input type="radio" name="ways" onClick={(e) => setWays(2)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Twoway')} </label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-6">
                <label class="frm-label">{I18n.t('TrackingPrice.Airportcar')} </label>
                <div class="row">
                    <div class="col-xs-4">
                        <div class="form-group">
                            <input type="radio" name="airport" onClick={(e) => setAirport(1)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Oneway')} </label>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group">
                            {/* <input type="number" class="form-control" ref={HeightRef} value={Height} {...bindHeight} placeholder={I18n.t('TrackingPrice.Height')} /> */}
                            <input type="radio" name="airport" onClick={(e) => setAirport(2)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Twoway')} </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                {/* <label class="frm-label">{I18n.t('TrackingPrice.Size')} ({I18n.t('TrackingPrice.Length')} x {I18n.t('TrackingPrice.Width')} x {I18n.t('TrackingPrice.Height')})  (cm)</label> */}
                <label class="frm-label">{I18n.t('TrackingPrice.Shipper')} </label>
                <div class="row">
                    <div class="col-xs-4">
                        <div class="form-group">
                            <input type="radio" name="ship" onClick={(e) => setKeyAbsolute(1)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Normal')} </label>
                        </div>
                    </div>
                    <div class="col-xs-4">
                        <div class="form-group">
                            <input type="radio" name="ship" onClick={(e) => setKeyAbsolute(2)} />
                            <label class="frm-label margin-bot-10 margin-left-10">{I18n.t('TrackingPrice.Express')} </label>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-12 text-center">
                <button type="button" class="btn btn-success pd-30" disabled={disabled} onClick={spCallCar_Save}>{I18n.t('TrackingPrice.TrackingPrice')}</button>
                <a target="_blank" href="/quy-dinh-chung?title=quy-dinh-vat-cam-gui-va-gui-co-dieu-kien" class="btn btn-outline">{I18n.t('TrackingPrice.RegulationsAndGoods')}</a>
            </div>
            <p className="text-red text-center">Lưu ý: Cước phí ước tính chưa bao gồm phí phát ngoại tuyến, VAT và phụ phí xăng dầu</p>
            {
                ShowPrice === 1 ?
                    (
                        <div class="col-md-12">
                            <div class="h3 text-green">{I18n.t('TrackingPrice.TheRightServiceForYou')}</div>
                            <div class="row">
                                {
                                    ListPrice.map((item, index) => {
                                        let _imgThumb = "/assets/img/icon80/cpn.png";
                                        return (
                                            <div class="col-md-4" key={"Price" + index}>
                                                <div class="box-item">
                                                    <img alt={item.ServiceName} class="img-normal" src={_imgThumb} />
                                                    <div class="text-bolder">{item.ServiceName}</div>
                                                    <div class="text-lg-bolder">{FormatMoney(item.Amount)} VNĐ</div>
                                                    <div class="text-sm">{I18n.t('TrackingPrice.EstimatedDealine')}: {FormatDateJson(item.DealineTime, 1)}</div>
                                                    {/* <a href="https://customer.netco.com.vn/tao-nhanh-van-don" class="btn btn-success">{I18n.t('TrackingPrice.CreateLading')}</a> */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (<></>)
            }
        </div>
    );
};

export const TrackingPrice = React.memo(TrackingPriceComp);
