import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { APIKeySystem } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { Alerterror, Alertwarning, EncodeString } from "../../Utils";
import { useInput } from "../../Hooks";
import I18n from '../../Language';

export const Login = () => {
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);

    const [ShowPass, setShowPass] = useState(false);
    const [UserName, bindUserName, setUserName] = useInput("");
    const [Password, bindPassword, setPassword] = useInput("");

    const UsernameRef = useRef();
    const PasswordRef = useRef();

    useEffect(() => {
        localStorage.setItem("login", "");
    }, []);

    const HandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onAction();
        }
    }

    const onAction = async () => {
        if (UserName === "") {
            Alertwarning(I18n.t("User.RequiredUserName"));
            UsernameRef.current.focus();
            return;
        } else if (Password === "") {
            Alertwarning(I18n.t("User.RequiredPassword"));
            PasswordRef.current.focus();
            return;
        } else {
            setDisabled(true);
            const params = {
                Json: Password,
                func: "APIC_GetEncoding",
            };

            try {
                const pwd = await mainAction.API_spCallServerSystem(params, dispatch);
                const pr = {
                    Json: '{"UserName":"' + UserName + '","Password":"' + pwd.data + '"}',
                    func: "APIC_spCustomerCheckLogin_Json",
                };

                const list = await mainAction.API_spCallServerSystem(pr, dispatch);
                if (list.length > 0) {
                    let ensc = EncodeString(JSON.stringify(list[0]));
                    localStorage.setItem("login", ensc);
                    window.location.href = "/";
                }
                else {
                    Alerterror(I18n.t("Alert.UserNameOrPasswordFail"));
                    setDisabled(false);
                }
            } catch (err) {
                setDisabled(false);
            }
        }

    }

    return (
        <section class="login">
            <div class="row">
                <div class="col-md-6" style={{ backgroundImage: "url('/assets/img/389a12.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "500px" }}>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 form-pd">
                    <h2>{I18n.t("User.Login")}</h2>
                    <div class="text mb20">{I18n.t("User.YouStillDontHaveAnAccount")} <Link class="bold" to="/dang-ky">{I18n.t("User.RegisterNow")}</Link></div>
                    <div class="form-group">
                        <label class="frm-label">{I18n.t("User.EmailOrPhoneNumber")}</label>
                        <input type="text" class="form-control" ref={UsernameRef} required value={UserName} {...bindUserName} placeholder={I18n.t("User.EnterYourEmailOrPhoneNumber")} />
                    </div>
                    <div class="form-group">
                        <label class="frm-label">{I18n.t("User.Password")}</label>
                        <div class="input-group">
                            <input type={ShowPass ? "text" : "password"} ref={PasswordRef} required value={Password} {...bindPassword} class="form-control" placeholder={I18n.t("User.EnterYourPassword")} onKeyDown={(e) => HandleKeyDown(e)} />
                            <div class="input-group-append">
                                <button class="btn btn-default" type="button" onClick={(e) => { setShowPass(!ShowPass) }}>
                                    <i class={ShowPass ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-right mb20"><a target="_blank" rel="noreferrer" class="forgot-pw" href="http://localhost:3000/forgot">{I18n.t("User.ForgotYourPassword")}</a></div>
                    <div class="form-group">
                        <button type="button" class="btn btn-success w-100" disabled={disabled} onClick={(e) => onAction()}>{I18n.t("User.Login")}</button>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </section>
    )
}