import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtml from 'raw-html-react';

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop } from "../../Utils";
import I18n from '../../Language';

export const Services = () => {
    const dispatch = useDispatch();

    const [DomesticService, setDomesticService] = useState([]);
    const [InternationalService, setInternationalService] = useState([]);
    const [TypeService, setTypeService] = useState(1);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = async () => {
        let keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: '{"Type":"Service","Enabled":1,"Lang":"' + keyLang + '"}',
            func: "WS_spGetInformation",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            if (list.length > 0) {
                setDomesticService(list.filter(p => p.Type === "Service" && p.Keys === 'Domestic'));
                setInternationalService(list.filter(p => p.Type === "Service" && p.Keys === 'International'));
            }
            ScrollTop();
        } catch (err) {
        }
    };

    return (
        <section class="content services">
            <div class="container mb0">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("TopMenu.Service")}</span>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h3 class="mt0"><ReactHtml html={I18n.t('Service.MultipleService')} componentMap={{ Services }} /></h3>
                        <div class="text-lg-bold"><span class={TypeService === 1 ? "active cursor" : "cursor"} onClick={(e) => setTypeService(1)}>Trong nước</span> | <span class={TypeService === 2 ? "active cursor" : "cursor"} onClick={(e) => setTypeService(2)}>Quốc tế</span></div>
                        <div class="box-service">
                            {
                                TypeService === 1 ? (
                                    <ul class="service-child">
                                        {
                                            DomesticService.map((item, index) => {
                                                return (
                                                    <li key={item.Id + "SERVICE"}>
                                                        <Link title={item.Title} to={"/dich-vu?title=" + item.Url}>
                                                            <div class="box-item">
                                                                <img alt={item.Title} class="img-normal" src={FILE_URL + item.ImgThumb} />
                                                                <img alt={item.Title} class="img-hover" src={FILE_URL + item.ImgFull} />
                                                                <div class="text">{item.Title}</div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <ul class="service-child">
                                        {
                                            InternationalService.map((item, index) => {
                                                return (
                                                    <li key={item.Id + "SERVICE"}>
                                                        <Link title={item.Title} to={"/dich-vu?title=" + item.Url}>
                                                            <div class="box-item">
                                                                <img alt={item.Title} class="img-normal" src={FILE_URL + item.ImgThumb} />
                                                                <img alt={item.Title} class="img-hover" src={FILE_URL + item.ImgFull} />
                                                                <div class="text">{item.Title}</div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}