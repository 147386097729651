import React, { useState, useEffect } from "react";
import { withGoogleMap, withScriptjs, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps"
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'

import I18n from '../Language';

import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_ZOOM, GOOGLE_MAP_LOCATION } from "../Services";

const GMapComp = ({
    dataMarker = [],
    dataDirection = [],
    dataZoom = {}
}) => {

    const options = { closeBoxURL: '', enableEventPropagation: true };
    const [Data, setData] = useState([]);

    useEffect(() => {
        setData(dataMarker);
        setShowCurrentInfo(false);
        
        if (dataZoom!==null) {
            ZoomPO(dataZoom);
        }
        else if (dataDirection.length >= 2)
            CreateRoute(dataDirection);

    }, [dataMarker, dataDirection, dataZoom]);

    //#region Chỉ đường

    const [directions, setDirections] = useState(null);
    const [FromLocation, setFromLocation] = useState(null);
    const [Zoom, setZoom] = useState(GOOGLE_MAP_ZOOM);
    const [Center, setCenter] = useState(GOOGLE_MAP_LOCATION);

    const CreateRoute = (dataRoute) => {
        let origin = { lat: +dataRoute[0].lat, lng: +dataRoute[0].lng };
        let destination = {};

        dataRoute[0].IsReturn ?
            destination = origin :
            destination = { lat: +dataRoute[dataRoute.length - 1].lat, lng: +dataRoute[dataRoute.length - 1].lng };
        setFromLocation(origin)

        const poinst = dataRoute.map(i => {
            return { location: { lat: +i.lat, lng: +i.lng }, stopover: true }
        })

        const DirectionsService = new window.google.maps.DirectionsService();
        DirectionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints: poinst
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                    setZoom(14);
                } else {
                    return;
                }
            }
        );
    }
    //#endregion

    //#region Khởi tạo Map

    const [ShowCurrentInfo, setShowCurrentInfo] = useState(false);

    const ShowTooltip = (item) => {
        let a = [...Data];
        a.map((i, k) => {
            if (i.PostOfficeID === item.PostOfficeID)
                i.showInfoWindow = true
            else
                i.showInfoWindow = false;
        })
        setData(a);
        setCenter({ lat: item.Lat, lng: item.Lng });
        setZoom(15);
    }

    const HideTooltip = () => {
        let a = [...Data];
        a.map((i, k) => {
            i.showInfoWindow = false;
        })
        setData(a);
    }

    const ZoomPO = (item)=>{
        setCenter({ lat: item.Lat, lng: item.Lng });
        setZoom(15);
    }

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            defaultZoom={Zoom}
            defaultCenter={Center}
        >
            {Data.length > 0 && Data.map((item, index) =>
                <Marker
                    key={"marker" + index}
                    icon={{
                        url: `./assets/img/placeholder.png`,
                        scaledSize: new window.google.maps.Size(20, 30),
                    }}
                    position={{ lat: item.Lat, lng: item.Lng }}
                    onClick={(e) => ShowTooltip(item)}
                >
                    {item.showInfoWindow && (
                        <InfoBox options={options}>
                            <>
                                <div style={{ color: '#333', backgroundColor: "#fff" }}>
                                    <div class="pull-right"><i class="fa fa-times" onClick={(e) => HideTooltip()}></i></div>
                                    <p className="bold" style={{ textTransform: "uppercase" }}>
                                        {item.POName}
                                    </p>
                                    <p>
                                        <i className="fa fa-map-marker green"></i> {item.POAddress}
                                    </p>
                                    <p>
                                        <i className="fa fa-phone green"></i> {item.POPhone}
                                    </p>
                                </div>
                            </>
                        </InfoBox>
                    )}
                </Marker>
            )}

            {dataDirection.length > 0 && <Marker
                position={FromLocation}
                icon={{
                    url: `./assets/img/position.png`,
                    scaledSize: new window.google.maps.Size(30, 30),
                }}
                onClick={(e) => { setShowCurrentInfo(true); }}
            >
                {ShowCurrentInfo && (<InfoBox options={options}>
                    <>
                        <div style={{ color: '#333', backgroundColor: "#fff" }}>
                            <div class="pull-right"><i class="fa fa-times" onClick={(e) => { setShowCurrentInfo(false); }}></i></div>
                            <span className="bold" style={{ textTransform: "uppercase" }}>
                                {I18n.t("PostOfficePage.Here")}
                            </span>
                        </div>
                    </>
                </InfoBox>
                )}
            </Marker>}

            {directions &&
                <DirectionsRenderer
                    directions={directions}
                    options={{
                        polylineOptions: {
                            strokeOpacity: 1,
                            strokeColor: '#f9511f',
                            strokeWeight: 4,
                        },
                        markerOptions: {
                            visible: false,
                        },
                    }}
                />
            }

        </GoogleMap>
    ));

    //#endregion

    return (
        <MapWithAMarker
            isMarkerShown
            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places"}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: "500px" }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    )
}

export const GMap = React.memo(GMapComp);