import axios from 'axios'

export const API_WS = 'http://xemuonnoi-admin.vps.vn/api/apiws/'
// export const API_WS = 'http://localhost:3369/api/apiws/'
export const APIKey = 'netCoApi2022'

export const API_ERP = 'http://api-test-customer.netco.vn/api'
export const APIKeyErp = 'netcoApikey2025'

export const API_CHAT = 'http://localhost:6451/api/ApiMB'

export const FILE_URL = 'http://xemuonnoi-admin.vps.vn/';
// export const FILE_URL = 'http://localhost:3369/';
export const GOOGLE_LOGIN_CLIENTID = '418580183625-h3psg5ke3ri923qg5kuos64jmr0j2fuj.apps.googleusercontent.com'
export const FACEBOOK_LOGIN_APPID = '836612320191788'

export const GOOGLE_MAP_API_KEY = 'AIzaSyBUBW5JbPqpurOUq2iV3Ys3rx59IktH1-s'

export const GOOGLE_MAP_ZOOM = 5;
export const GOOGLE_MAP_CENTER = [14.775869, 106.688661];
export const GOOGLE_MAP_LOCATION = { lat: 14.775869, lng: 106.688661 };
export const CUSTOMER_CREATE_LADING_URL = "https://customer.netco.com.vn/tao-nhanh-van-don"
export const CHAT_USERS = [
   
]

export const CHAT_USERS_LIST = [
   
]

export const CHAT_LIST_USER = [-1]

export const CHAT_GROUPS = []

const setToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
};

axios.defaults.headers.post["Content-Type"] = "application/json";

const apiWS = axios.create({
    baseURL: API_WS
})

const apiErp = axios.create({
    baseURL: API_ERP
})

const apiChat = axios.create({
    baseURL: API_CHAT
})

export default setToken;
export { apiWS, apiErp, apiChat };
