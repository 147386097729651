import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { FormatDateJson, ScrollTop } from "../../Utils";
import I18n from '../../Language';

export const News = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [Info, setInfo] = useState([]);
    const [Info2, setInfo2] = useState([]);
    const [Info3, setInfo3] = useState([]);

    const [Title, setTitle] = useState(I18n.t('News.TitlePromotion'));
    const [Title2, setTitle2] = useState(I18n.t('News.TitleActivate'));
    const [Title3, setTitle3] = useState(I18n.t('News.TitleTutorial'));

    const [Link1, setLink1] = useState("");
    const [Link2, setLink2] = useState("");
    const [Link3, setLink3] = useState("");

    useEffect(() => {
        if (location.pathname === "/hoat-dong") {
            setTitle(I18n.t('News.TitleActivate'));
            setTitle2(I18n.t('News.TitlePromotion'));
            setTitle3(I18n.t('News.TitleTutorial'));

            setLink1("hoat-dong");
            setLink2("khuyen-mai");
            setLink3("huong-dan");

            onGetData("Activate");
            onGetData2("Promotion");
            onGetData3("Hepper");
        }
        else if (location.pathname === "/huong-dan") {
            setTitle(I18n.t('News.TitleTutorial'));
            setTitle2(I18n.t('News.TitlePromotion'));//1
            setTitle3(I18n.t('News.TitleActivate'));//2

            setLink1("huong-dan");
            setLink2("khuyen-mai");
            setLink3("hoat-dong");

            onGetData("Hepper");
            onGetData2("Promotion");
            onGetData3("Activate");
        }
        else {
            setTitle(I18n.t('News.TitlePromotion'));//1
            setTitle2(I18n.t('News.TitleActivate'));//2
            setTitle3(I18n.t('News.TitleTutorial'));//3

            setLink1("khuyen-mai");
            setLink2("hoat-dong");
            setLink3("huong-dan");

            onGetData("Promotion");
            onGetData2("Activate");
            onGetData3("Hepper");
        }
    }, [location]);

    const onGetData = async (type, take) => {
        const params = {
            Json: '{"TypeNews":"' + type + '","Take":10}',
            func: "WS_spGetNews",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setInfo(list);
            ScrollTop();
        } catch (err) {
        }
    };

    const onGetData2 = async (type) => {
        const params = {
            Json: '{"TypeNews":"' + type + '","Take":2}',
            func: "WS_spGetNews",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setInfo2(list);
        } catch (err) {
        }
    }

    const onGetData3 = async (type) => {
        const params = {
            Json: '{"TypeNews":"' + type + '","Take":2}',
            func: "WS_spGetNews",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setInfo3(list);
        } catch (err) {
        }
    }

    return (
        <section class="content">
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{Title}</span>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <h3>{Title}</h3>
                        <div class="row">
                            {
                                Info.map((item, index) => {
                                    return (
                                        <div class="col-md-6" key={"news1" + index}>
                                            <div class="box-news">
                                                <Link to={"/chi-tiet-" + Link1 + "?title=" + item.Url} title={item.Name}>
                                                    <img class="img-normal" src={FILE_URL + item.ImgThumb} alt={item.Name} />
                                                    <div class="text-bold">{item.Name}</div>
                                                    <div class="text-xs"><i class="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}
                                                        <div class="pull-right">
                                                            {I18n.t('News.ReadMore')}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div class="col-md-4 mt20">
                        <h5>{Title2}
                            <div class="pull-right text-sm pt10">
                                <Link class="link-blue" to={"/" + Link2}>{I18n.t('News.ReadMore')}</Link>
                            </div>
                        </h5>
                        <div class="row">
                            {
                                Info2.map((item, index) => {
                                    return (
                                        <div class="col-md-12" key={"news2" + index}>
                                            <div class="box-news-right">
                                                <Link to={"/chi-tiet-" + Link2 + "?title=" + item.Url} title={item.Name}>
                                                    <img class="img-thumb" src={FILE_URL + item.ImgThumb} alt={item.Name} />
                                                    <div class="item-right">
                                                        <div class="text-sm">{item.Name}</div>
                                                        <div class="text-xs"><i class="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <h5 class="mt30">{Title3}
                            <div class="pull-right text-sm pt10">
                                <Link class="link-blue" to={"/" + Link3}>{I18n.t('News.ReadMore')}</Link>
                            </div>
                        </h5>
                        <div class="row">
                            {
                                Info3.map((item, index) => {
                                    return (
                                        <div class="col-md-12" key={"news3" + index}>
                                            <div class="box-news-right">
                                                <Link to={"/chi-tiet-" + Link3 + "?title=" + item.Url}>
                                                    <img class="img-thumb" src={FILE_URL + item.ImgThumb} />
                                                    <div class="item-right">
                                                        <div class="text-sm">{item.Name}</div>
                                                        <div class="text-xs"><i class="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}