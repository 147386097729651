import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useInput } from "../../Hooks";
import { mainAction } from "../../Redux/Actions";
import { Alertwarning, DecodeString, EncodeString, FormatDateJson, getData, setData } from "../../Utils";
import { CHAT_LIST_USER, CHAT_USERS, CHAT_GROUPS, CHAT_USERS_LIST } from "../../Services";
import I18n from '../../Language';

export const Chat = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [Name, bindName, setName] = useInput("");
    const [Email, bindEmail, setEmail] = useInput("");
    const [Phone, bindPhone, setPhone] = useInput("");
    const [TypeContact, setTypeContact] = useState("");
    const [Area, setArea] = useState("");

    const NameRef = useRef();
    const EmailRef = useRef();
    const PhoneRef = useRef();

    const [Message, bindMessage, setMessage] = useInput("");
    const MessageRef = useRef();

    const [Data, setData] = useState([]);

    const [ChatInfo, setChatInfo] = useState(localStorage.getItem("ChatInformation"));
    const [ChatInfoGroup, setChatInfoGroup] = useState(localStorage.getItem("ChatInformationGroup"));
    const [ChatTemp, setChatTemp] = useState([]);

    useEffect(() => {
        if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
            onGetData();
            setInterval(() => {
                onGetData();
            }, 30000);
        }
    }, []);

    const MessageHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSend();
        }
    }

    const onStartChat = () => {
        if (Name === "") {
            Alertwarning(I18n.t("ContactForm.RequiredName"));
            NameRef.current.focus();
            return;
        } else if (Email === "") {
            Alertwarning(I18n.t("ContactForm.RequiredEmail"));
            EmailRef.current.focus();
            return;
        } else if (Phone === "") {
            Alertwarning(I18n.t("ContactForm.RequiredPhone"));
            PhoneRef.current.focus();
            return;
        } else if (TypeContact === "") {
            Alertwarning(I18n.t("ContactForm.RequiredType"));
            return;
        }
        else if (Area === "") {
            Alertwarning(I18n.t("ContactForm.RequiredArea"));
            return;
        } else {
            setDisabled(true);
            //Lưu key chat vào localstorage
            const userChat = CHAT_USERS.find(p => p.Type === TypeContact && p.Area === Area);
            if (userChat !== undefined) {
                let pr = {
                    NameSend: Name,
                    Mail: Email,
                    Phone: Phone,
                    TypeContact: TypeContact,
                    StartTime: new Date(),
                    Area: Area,
                    ToId: userChat.UserId
                }
                let ensc = EncodeString(JSON.stringify(pr));
                localStorage.setItem("ChatInformation", ensc);
                setChatInfo(ensc);
                setChatTemp([]);
                onReset();
                setDisabled(false);
                setInterval(() => {
                    onGetData();
                }, 30000);
            }
            const groupChat = CHAT_GROUPS.find(p => p.Type === TypeContact && p.Area === Area);
            if (groupChat !== undefined) {
                let pr = {
                    NameSend: Name,
                    Mail: Email,
                    Phone: Phone,
                    TypeContact: TypeContact,
                    StartTime: new Date(),
                    Area: Area,
                    ToId: groupChat.UserId
                }
                let ensc = EncodeString(JSON.stringify(pr));
                localStorage.setItem("ChatInformationGroup", ensc);
                setChatInfoGroup(ensc);
            }
        }
    }

    const onStopChat = () => {
        if (window.confirm("Bạn muốn thoát khỏi cuộc trò chuyện ?")) {
            localStorage.setItem("ChatInformation", "");
            setChatInfo("");
            setChatTemp([]);
            window.location.reload();
        }
    }

    const onReset = () => {
        setName("");
        setPhone("");
        setEmail("");
    }

    const onSend = async () => {
        if (Message === "") {
            Alertwarning(I18n.t("Chat.RequiredMessage"));
            MessageRef.current.focus();
            return;
        } else {
            setDisabled(true);
            //Lưu key chat vào localstorage
            let pr = JSON.parse(DecodeString(ChatInfo));
            pr.func = "SendMessage";
            pr.Message = Message;

            if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
                try {
                    const list = await mainAction.API_spCallServerChat(pr, dispatch);
                    let _tmp = [...ChatTemp];
                    _tmp.push(Message);
                    setChatTemp(_tmp);
                    setMessage("");
                    onGetData();
                    setDisabled(false);
                } catch (err) {
                    setDisabled(false);
                }
            }

            const userChat = CHAT_USERS_LIST.filter(p => p.Type === TypeContact && p.Area === Area);
            let mes = '<h3>Customer send message from website netco.com.vn</h3>'
                + "<p>Name: <b>" + pr.NameSend + "</b><br />"
                + "Email: <b>" + pr.Mail + "</b><br />"
                + "Tel: <b>" + pr.Phone + "</b><br />"
                +"Area: <b>"+pr.Area + "</b><br />"
                + "Message: <b>" + Message + "</b></p>";
            userChat.forEach((element, index) => {
                try {
                    const NotifiParam = {
                        Json: JSON.stringify({
                            CustomerId: 0,
                            FuncSend: "NotifyChat",
                            HtmlNofity: mes,
                            UserReceipt: element.UserId
                        }),
                        func: "APIC_spSendNotification",
                    };
                    const resultNotify = mainAction.API_spCallServerSystem(
                        NotifiParam,
                        dispatch
                    );
                } catch (err) {
                    setDisabled(false);
                }
            })

            /* if (ChatInfoGroup !== undefined && ChatInfoGroup !== null && ChatInfoGroup !== '') {
                let pr = JSON.parse(DecodeString(ChatInfoGroup));
                pr.func = "SendMessage";
                pr.Message = "<p>Khách hàng gửi tin nhắn đến khu vực của bạn:</p>" + Message;
                try {
                    const list = await mainAction.API_spCallServerChat(pr, dispatch);
                    let _tmp = [...ChatTemp];
                    _tmp.push(Message);
                    setChatTemp(_tmp);
                    setMessage("");
                    onGetData();
                    setDisabled(false);
                } catch (err) {
                    setDisabled(false);
                }
            } */
        }
    }

    const onGetData = async () => {
        try {
            if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
                //Lưu key chat vào localstorage
                let pr = JSON.parse(DecodeString(ChatInfo));
                pr.func = "GetMessage";
                const list = await mainAction.API_spCallServerChat(pr, dispatch);
                setData(list);
                setChatTemp([]);
            }
        } catch (err) {
            setDisabled(false);
        }
    }

    return (
        <>
            <div class="card chat-area">
                {/* <div class="card-header" id="headingChat">
                    <h5 class="mb-0">
                        <a class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat">
                            Chat với NETCO POST
                        </a>
                        <div class="pull-right">
                            <i class="fa fa-ellipsis-v" onClick={(e) => onStopChat()} title="Click để thoát khỏi cuộc trò chuyện"></i>
                            <i class="fa fa-minus" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat"></i>
                        </div>
                    </h5>
                </div>
                <div id="collapseChat" class="collapse" aria-labelledby="headingChat" data-parent="#accordion">
                    <div class="card-body">
                        {
                            ChatInfo === null || ChatInfo === "" || ChatInfo === undefined ? (
                                <div class="chat-form-start" id="collapseExample">
                                    <div class="form">
                                        <div class="form-group">
                                            <label class="special-label">{I18n.t("ContactPage.InputName")}</label>
                                            <input class="form-control" type="text" ref={NameRef} value={Name} {...bindName} maxLength="50" placeholder={I18n.t("ContactForm.InputName")} required />
                                        </div>
                                        <div class="form-group">
                                            <label class="special-label">{I18n.t("ContactPage.InputEmail")}</label>
                                            <input class="form-control" type="text" ref={EmailRef} value={Email} {...bindEmail} maxLength="50" placeholder={I18n.t("ContactForm.InputEmail")} required />
                                        </div>
                                        <div class="form-group">
                                            <label class="special-label">{I18n.t("ContactPage.InputPhone")}</label>
                                            <input class="form-control" type="text" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
                                        </div>
                                        <div class="form-group mb0">
                                            <label>{I18n.t("ContactForm.WhatDoYouNeed")}</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="form-check" onClick={(e) => setTypeContact("ShippingConsulting")}>
                                                        {I18n.t("ContactForm.ShippingConsulting")}
                                                        <input type="radio" name="radioChatType" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-md-12">
                                                    <label class="form-check" onClick={(e) => setTypeContact("CustomerCare")}>
                                                        {I18n.t("ContactForm.CustomerCare")}
                                                        <input type="radio" name="radioChatType" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb0">
                                            <label>{I18n.t("ContactForm.SelectYourArea")}</label>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label class="form-check" onClick={(e) => setArea("North")}>
                                                        {I18n.t("ContactForm.North")}
                                                        <input type="radio" name="radioAreaChat" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-check" onClick={(e) => setArea("Middle")}>
                                                        {I18n.t("ContactForm.Middle")}
                                                        <input type="radio" name="radioAreaChat" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="form-check" onClick={(e) => setArea("South")}>
                                                        {I18n.t("ContactForm.South")}
                                                        <input type="radio" name="radioAreaChat" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group text-right mb20">
                                            <button type="button" class="btn btn-success pd-30" disabled={disabled} onClick={(e) => onStartChat()}>{I18n.t("Chat.ChatNow")}</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div class="chat-content">
                                    <div class="list-message">
                                        <div key="msg0" class="msg in">
                                            <img class="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
                                            <div class="msg-content">{I18n.t("Chat.CanIHelpYou")}</div>
                                        </div>
                                        {
                                            Data.map((item, index) => {
                                                if (CHAT_LIST_USER.indexOf(item.Id_Send) !== -1)
                                                    return (
                                                        <div key={"msg" + index} class="msg in">
                                                            <img class="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
                                                            <div class="msg-content">
                                                                {item.Message}
                                                                <div class="msg-time">{FormatDateJson(item.Creatdate)}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                else
                                                    return (
                                                        <>
                                                            <div key={"msg" + index} class="msg out">
                                                                {item.Message}
                                                                <div class="msg-time">{FormatDateJson(item.Creatdate)}</div>
                                                            </div>
                                                            {(index + 1 === Data.length ? (<div key={"msg" + index} class="msg in">
                                                                <img class="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
                                                                <div class="msg-content">
                                                                    {I18n.t("Chat.AutoChat")}
                                                                    <div class="msg-time">{FormatDateJson(item.Creatdate)}</div>
                                                                </div>
                                                            </div>) : (<></>))}
                                                        </>
                                                    )

                                            })
                                        }
                                        {
                                            ChatTemp.map((item, index) => {
                                                return (<div key={"msg_tmp" + index} class="msg out">
                                                    {item}
                                                    <div class="msg-time">{FormatDateJson(new Date())}</div>
                                                </div>)
                                            })
                                        }
                                    </div>
                                    <div class="form-group chat-input-group">
                                        <div class="input-group">
                                            <input class="form-control" type="text" onKeyDown={(e) => MessageHandleKeyDown(e)} ref={MessageRef} value={Message} {...bindMessage} placeholder={I18n.t("Chat.InputMessage")} required />
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-success" onClick={(e) => onSend()}>{I18n.t("Chat.Send")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div> */}
            </div>
        </>
    );
};