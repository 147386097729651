import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtml from 'raw-html-react';

import { useInput } from "../../Hooks";
import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { Alertwarning, Alertsuccess } from "../../Utils";
import I18n from '../../Language';
import { ContactForm } from "../../Common";

export const BlockContact = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const [Phone, bindPhone, setPhone] = useInput("");
  const PhoneRef = useRef();

  const [Type, setType] = useState(0);
  const [TypeContact, setTypeContact] = useState("");

  const [AreaId, setAreaId] = useState(0);
  const [Area, setArea] = useState("");

  useEffect(() => {

  }, []);

  const onActionPhone = async () => {
    if (Type === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredType"));
      return;
    }
    else if (AreaId === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredArea"));
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("ContactForm.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else {
      setDisabled(true);
      const pr = {
        Json: '{"Phone":"' + Phone + '", "Type":"' + Type + '", "TypeName":"' + TypeContact + '", "AreaId":"' + AreaId + '", "AreaName": "' + Area + '","Status":1, "StatusName":"Chưa xử lý"}',
        func: "CRM_spCustomerContactRequest_Save",
      };
      try {
        const list = await mainAction.API_spCallServerSystem(pr, dispatch);
        onSendNofity();
        if (list !== []) {
          Alertsuccess(I18n.t("ContactForm.Success"));
          onReset();
        }

        setDisabled(false);
      } catch (err) {
        setDisabled(false);
      }
    }
  }

  const onReset = () => {
    setPhone("");
  }

  const onSendNofity = async () => {
    debugger
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: "CallRequest",
        JsonData: [
          {
            Area: AreaId,
            Phone: Phone,
            Type: Type,
          },
        ],
      }),
      func: "APIC_spSendNotification",
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
  }

  return (
    <>
      {/* <div class="block-absolute">
        <a href="#" data-toggle="modal" data-target="#phoneModal">
          <div class="item-absolute">
            <img class="main-img" width="25" height="25" src="/assets/img/green/phone.png" alt="phone" />
            <img class="hover-img" width="25" height="25" src="/assets/img/white/phone.png" alt="phone" />
          </div>
        </a>
        <a href="#" data-toggle="modal" data-target="#emailModal">
          <div class="item-absolute">
            <img class="main-img" width="25" height="25" src="/assets/img/green/email.png" alt="phone" />
            <img class="hover-img" width="25" height="25" src="/assets/img/white/email.png" alt="phone" />
          </div>
        </a>
        <Link to="/diem-gui-hang" title="Hệ thống bưu cục">
          <div class="item-absolute">
            <img class="main-img" width="25" height="25" src="/assets/img/green/map.png" alt="phone" />
            <img class="hover-img" width="25" height="25" src="/assets/img/white/map.png" alt="phone" />
          </div>
        </Link>
        <a href="https://www.facebook.com/netcopost.com.vn" target="_blank" title="NETCO POST">
          <div class="item-absolute">
            <img class="main-img" width="25" height="25" src="/assets/img/green/facebook.png" alt="phone" />
            <img class="hover-img" width="25" height="25" src="/assets/img/white/facebook.png" alt="phone" />
          </div>
        </a>
        <a href="#" id="btnShowPopup" class="display-none" data-toggle="modal" data-target="#popupModal">
          <div class="item-absolute" style={{ lineHeight: "25px", width: "37px", textAlign: "center", fontSize: "20px" }}>
            <i class="fa fa-bell-o text-green"></i>
          </div>
        </a>
      </div>
      <div class="modal fade" id="phoneModal" tabIndex="-1" role="dialog" aria-labelledby="phoneModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 class="modal-title mb20 grey80"><ReactHtml html={I18n.t("ContactForm.ContactPhone")} componentMap={{ BlockContact }} /></h5>
              <div class="form-group">
                <label>{I18n.t("ContactForm.WhatDoYouNeed")} <span class="text-red">(*)</span></label>
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-check" onClick={(e) => { setTypeContact("TVDV"); setType(2); }}>
                      {I18n.t("ContactForm.ShippingConsulting")}
                      <input type="radio" name="radioTypeCall" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-md-6">
                    <label class="form-check" onClick={(e) => { setTypeContact("CSKH"); setType(1); }}>
                      {I18n.t("ContactForm.CustomerCare")}
                      <input type="radio" name="radioTypeCall" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{I18n.t("ContactForm.SelectYourArea")} <span class="text-red">(*)</span></label>
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-check" onClick={(e) => { setArea("Miền Bắc"); setAreaId(10); }}>
                      {I18n.t("ContactForm.North")}
                      <input type="radio" name="radioAreaCall" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label class="form-check" onClick={(e) => { setArea("Miền Trung"); setAreaId(9); }}>
                      {I18n.t("ContactForm.Middle")}
                      <input type="radio" name="radioAreaCall" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label class="form-check" onClick={(e) => { setArea("Miền Nam"); setAreaId(11); }}>
                      {I18n.t("ContactForm.South")}
                      <input type="radio" name="radioAreaCall" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" type="text" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
                  <div class="input-group-append">
                    <button class="btn btn-success" onClick={(e) => onActionPhone()}>{I18n.t("ContactForm.CallMe")}</button>
                  </div>
                </div>
              </div>
              <p class="text-center">{I18n.t("ContactForm.NotePhone")}</p>
              <p class="h4 text-center grey80">1900 6463</p>
              <p class="h4 text-center grey80">02438 356 356</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="emailModal" tabIndex="-1" role="dialog" aria-labelledby="emailModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 class="grey80">{I18n.t("ContactForm.ContactEmail")}</h5>
              <ContactForm key="block-contact" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};