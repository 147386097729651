import React from "react";
import { Link } from "react-router-dom";
import { MapView, ContactForm } from "../../Common";
import I18n from '../../Language';

export const Contacts = () => {

    return (
        <section class="content">
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("TopMenu.ContactUs")}</span>
                </div>
                <div class="row mt10">
                    <div class="col-md-12">
                        <h3>{I18n.t('ContactPage.Title')}</h3>
                    </div>
                    <div class="col-md-6 form-pd">
                        <ContactForm key="contact-page" />
                    </div>
                    {/* <div class="col-md-6">
                        <MapView key="afghh" />
                    </div> */}
                </div>
            </div>
        </section >
    )
}