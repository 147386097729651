import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import{} from ""
import { useInput } from "../../Hooks";
import { mainAction } from "../../Redux/Actions";
import { MapView } from "../../Common";
import { Alertwarning, Alertsuccess } from "../../Utils";
import { APIKey } from "../../Services";
import I18n from '../../Language';

export const Complain = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [Name, bindName, setName] = useInput("");
    const [Phone, bindPhone, setPhone] = useInput("");
    const [Code, bindCode, setCode] = useInput("");
    const [Content, bindContent, setContent] = useInput("");
    const [Type, setType] = useState(1);

    const NameRef = useRef();
    const PhoneRef = useRef();
    const CodeRef = useRef();
    const ContentRef = useRef();

    const onAction = async () => {
        if (Name === "") {
            Alertwarning(I18n.t("Complain.RequiredName"));
            NameRef.current.focus();
            return;
        } else if (Phone === "") {
            Alertwarning(I18n.t("Complain.RequiredPhone"));
            PhoneRef.current.focus();
            return;
        } else if (Content === "") {
            Alertwarning(I18n.t("Complain.RequiredContent"));
            ContentRef.current.focus();
            return;
        } else {
            setDisabled(true);
            let params = {
                CustomerId: 0,
                Name: Name,
                Phone: Phone,
                LadingCode: Code,
                Type: parseInt(Type),
                Content: Content,
                SendFrom: "TỪ XE MUÔN NƠI WEBSITE",
            };
            const pr = {
                ApiKey: APIKey,
                Json: JSON.stringify(params),
                func: "APIC_spCustomerComplain_JsonAuto",
                IsLoading: true
            };
            try {
                const list = await mainAction.API_spCallServerSystem(pr, dispatch);
                if (list !== []) {
                    Alertsuccess(I18n.t("ContactForm.Success"));
                    onReset();
                    onSendNofity();
                }
                setDisabled(false);
            } catch (err) {
                setDisabled(false);
            }
        }
    }

    const onReset = () => {
        setName("");
        setPhone("");
        setCode("");
        setContent("");
    }

    const onSendNofity = async () => {
        const NotifiParam = {
            Json: JSON.stringify({
                CustomerId: 0,
                FuncSend: "ComplainCreate",
                SendFrom: "TỪ XE MUÔN NƠI WEBSITE",
                JsonData: [
                    {
                        Name: Name,
                        Phone: Phone,
                        Content: Content,
                    },
                ],
            }),
            func: "APIC_spSendNotification",
            API_key: APIKey,
        };
        const resultNotify = await mainAction.API_spCallServerSystem(
            NotifiParam,
            dispatch
        );
        if (resultNotify !== []) return;
    }

    return (
        <section class="content">
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("Complain.Title")}</span>
                </div>
                <h3>{I18n.t('Complain.Title')}</h3>
                <div class="row mt10">
                    <div class="col-md-6 form-pd">
                        <div class="form">
                            <div class="form-group">
                                <label>{I18n.t("Complain.InputName")} <span class="text-red">(*)</span></label>
                                <input class="form-control" type="text" required ref={NameRef} value={Name} {...bindName} maxLength="50" placeholder={I18n.t("Complain.InputName")} />
                            </div>
                            <div class="form-group">
                                <label>{I18n.t("Complain.InputPhone")} <span class="text-red">(*)</span></label>
                                <input class="form-control" type="text" required ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("Complain.InputPhone")} />
                            </div>
                            <div class="form-group">
                                <label>{I18n.t("Complain.InputCode")} <span class="text-red">(*)</span></label>
                                <input class="form-control" type="text" ref={CodeRef} value={Code} {...bindCode} maxLength="50" placeholder={I18n.t("Complain.InputCode")} />
                            </div>
                            <div class="form-group">
                                <label>{I18n.t("Complain.InputType")} <span class="text-red">(*)</span></label>
                                <select class="form-control" value={Type} onChange={(e) => setType(e.target.value)}>
                                    <option value="1">{I18n.t("Complain.ComplainService")}</option>
                                    <option value="2">{I18n.t("Complain.ComplainPrice")}</option>
                                    <option value="3">{I18n.t("Complain.ComplainCod")}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>{I18n.t("Complain.InputContent")} <span class="text-red">(*)</span></label>
                                <textarea class="form-control" rows="4" required ref={ContentRef} value={Content} {...bindContent} placeholder={I18n.t("Complain.ShortDesc")}></textarea>
                            </div>
                            <div class="form-group text-right mb20">
                                <button type="text" class="btn btn-success pd-30" disabled={disabled} onClick={(e) => onAction()}>{I18n.t("Complain.ButtonSend")}</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" >
                        {/* <MapView key="hhjgd" /> */}
                       
                        
                    </div>
                </div>
            </div>
        </section >
    )
}