import React from "react";
import ReactHtml from 'raw-html-react';
import { Link } from "react-router-dom";

import { PostOffice } from "../../Common";
import I18n from '../../Language';

export const Branch = () => {
    return (
        <section class="content">
            <div class="container">
                <div class="sidemap">
                    <span><Link to="/" title={I18n.t("TopMenu.Home")}>{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i class="fa fa-angle-right"></i></span>
                    <span>{I18n.t("PostOfficePage.Title")}</span>
                </div>
                <h3>{I18n.t('PostOfficePage.Title')}</h3>
                <div class="text-lg-bold mb10"><ReactHtml html={I18n.t('PostOfficePage.ShortDesc')} componentMap={{ Branch }} /></div>
                <div class="text mb10"><ReactHtml html={I18n.t('PostOfficePage.LongDesc')} componentMap={{ Branch }} /></div>
                <PostOffice />
            </div>
        </section >
    )
}