import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';

import { useInput } from "../../Hooks";
import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { Alertwarning, Alertsuccess, ScrollTop } from "../../Utils";
import I18n from '../../Language';

export const Partners = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    //#region  Form 1

    const [Name, bindName, setName] = useInput("");
    const [Email, bindEmail, setEmail] = useInput("");
    const [Phone, bindPhone, setPhone] = useInput("");
    const [Address, bindAddress, setAddress] = useInput("");
    const [CMND, bindCMND, setCMND] = useInput("");
    const [DateRange, bindDateRange, setDateRange] = useInput("");
    const [IssuedBy, bindIssuedBy, setIssuedBy] = useInput("");
    const [FileUpload, setFileUpload] = useState([]);

    const NameRef = useRef();
    const EmailRef = useRef();
    const PhoneRef = useRef();
    const AddressRef = useRef();
    const CMNDRef = useRef();
    const DateRangeRef = useRef();
    const IssuedByRef = useRef();

    //#endregion

    //#region Form 2

    const [AddressRegister, bindAddressRegister, setAddressRegister] = useInput("");
    const [Area, bindArea, setArea] = useInput("");
    const [Length, bindLength, setLength] = useInput("");
    const [Width, bindWidth, setWidth] = useInput("");
    const [Height, bindHeight, setHeight] = useInput("");
    const [StartTime, bindStartTime, setStartTime] = useInput("");

    const AddressRegisterRef = useRef();
    const AreaRef = useRef();
    const LengthRef = useRef();
    const WidthRef = useRef();
    const HeightRef = useRef();
    const StartTimeRef = useRef();

    //#endregion

    //#region Action register

    const onFileChange = (event) => {
        setFileUpload(event.target.files[0]);
        /* setShowChooseAvatar("display-none");
        setShowSaveAvatar("");
        setOldAvatar(Avatar);
        setAvatar(URL.createObjectURL(event.target.files[0])); */
    };

    const onAction = async () => {
        if (Name === "") {
            Alertwarning(I18n.t("AgentPage.RequiredName"));
            NameRef.current.focus();
            return;
        } else if (Phone === "") {
            Alertwarning(I18n.t("AgentPage.RequiredPhone"));
            PhoneRef.current.focus();
            return;
        }
        else if (Email === "") {
            Alertwarning(I18n.t("AgentPage.RequiredEmail"));
            EmailRef.current.focus();
            return;
        }
        else if (Address === "") {
            Alertwarning(I18n.t("AgentPage.RequiredAddress"));
            AddressRef.current.focus();
            return;
        }
        else {
            try {
                setDisabled(true);
                const pr = {
                    Name: Name,
                    Phone: Phone,
                    Email: Email,
                    Address: Address,
                    IdCard: CMND,
                    DateRange: DateRange,
                    IssuedBy: IssuedBy,
                    RegisterAddress: AddressRegister,
                    Length: Length,
                    Width: Width,
                    Height: Height,
                    RoundArea: Area,
                    StartTime: StartTime,
                    Image:""
                }

                const params = {
                    Json: JSON.stringify(pr),
                    func: "WS_spRegisterAgent",
                };

                const list = await mainAction.CallApiWs(params, dispatch);
                Alertsuccess(I18n.t("AgentPage.Success"));
                setDisabled(false);
                ScrollTop();
            } catch (err) {
                setDisabled(false);
            }
        }
    }

    //#endregion

    //#region Show html text

    const [Info, setInfo] = useState({});

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = async () => {
        let keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: '{"Type":"RegisterPartner","Enabled":1,"Lang":"' + keyLang + '"}',
            func: "WS_spGetInformation",
            IsLoading: true
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            if (list.length > 0)
                setInfo(list[0]);
            ScrollTop();
        } catch (err) {
        }
    };

    //#endregion

    return (
        <>
            <section class="content agent no-bg">
                <div class="container">
                    <div class="sidemap">
                        <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                        <span><i class="fa fa-angle-right"></i></span>
                        <span>{I18n.t("AgentPage.RegisterAgent")}</span>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="h3 mb10">{I18n.t("AgentPage.RegisterAgent")}</h1>
                        </div>
                        <div class="col-md-12 text-center mt20 mb20">
                            <img class="img-partner" src={FILE_URL + Info.ImgFull} />
                        </div>
                        <div class="col-md-12">
                            <ReactHtml html={Info.ShortDesc?.replaceAll("src=\"/image/", "src=\"" + FILE_URL + "image/").replaceAll("src=\"/Image/", "src=\"" + FILE_URL + "image/")} componentMap={{ Partners }} />
                        </div>
                    </div>
                </div>
            </section>
            <section class="content agent bg1">
                <div class="container">
                    <h3 class="text-center text-green">{I18n.t("AgentPage.AboutNetco")}</h3>
                    <div class="col-md-12">
                        <ReactHtml html={Info.LongDesc?.replaceAll("src=\"/image/", "src=\"" + FILE_URL + "image/").replaceAll("src=\"/Image/", "src=\"" + FILE_URL + "image/")} componentMap={{ Partners }} />
                    </div>
                </div>
            </section>
            <section class="content agent bg2">
                <div class="container">
                    <h3 class="text-center mb30 text-green">THÔNG TIN ĐĂNG KÝ ĐỐI TÁC THƯƠNG HIỆU</h3>
                    <div class="row form">
                        <h5 class="col-md-12 text-green mb20">{I18n.t("AgentPage.FormTitle1")}</h5>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputName")} <span class="required">*</span></label>
                                <input class="form-control" type="text" ref={NameRef} value={Name} {...bindName} maxLength="150" placeholder={I18n.t("AgentPage.InputName")} required />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputPhone")} <span class="required">*</span></label>
                                <input class="form-control" type="phone" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("AgentPage.InputPhone")} required />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputEmail")} <span class="required">*</span></label>
                                <input class="form-control" type="email" ref={EmailRef} value={Email} {...bindEmail} maxLength="50" placeholder={I18n.t("AgentPage.InputEmail")} required />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputAddress")} <span class="required">*</span></label>
                                <input class="form-control" type="text" ref={AddressRef} value={Address} {...bindAddress} placeholder={I18n.t("AgentPage.InputAddress")} required />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputCMND")}</label>
                                <input class="form-control" type="text" ref={CMNDRef} value={CMND} {...bindCMND} maxLength="10" placeholder={I18n.t("AgentPage.InputCMND")} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputDate")}</label>
                                <input class="form-control" type="date" ref={DateRangeRef} value={DateRange} maxLength="50" {...bindDateRange} placeholder={I18n.t("AgentPage.InputDate")} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputCity")}</label>
                                <input class="form-control" type="text" ref={IssuedByRef} value={IssuedBy} {...bindIssuedBy} placeholder={I18n.t("AgentPage.InputCity")} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputImage")} <i class="fa fa-info-circle" title={I18n.t("AgentPage.InputImage")}></i></label>
                                <input class="form-control" type="file" value={FileUpload} accept="image/*" onChange={onFileChange} placeholder={I18n.t("AgentPage.InputImage")} />
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-12 mt30">
                            <div class="liner"></div>
                        </div>
                        <h5 class="col-md-12 text-green mb20 mt30">{I18n.t("AgentPage.FormTitle2")}</h5>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputAddressRegister")}</label>
                                <input class="form-control" type="text" ref={AddressRegisterRef} value={AddressRegister} {...bindAddressRegister} placeholder={I18n.t("AgentPage.InputAddressRegister")} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputArea")}</label>
                                <input class="form-control" type="text" ref={AreaRef} maxLength="50" value={Area} {...bindArea} placeholder={I18n.t("AgentPage.InputArea")} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputLength")}</label>
                                <input class="form-control" type="text" ref={LengthRef} maxLength="50" value={Length} {...bindLength} placeholder={I18n.t("AgentPage.InputLength")} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputWidth")}</label>
                                <input class="form-control" type="text" ref={WidthRef} maxLength="50" value={Width} {...bindWidth} placeholder={I18n.t("AgentPage.InputWidth")} />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputHeight")}</label>
                                <input class="form-control" type="text" ref={HeightRef} maxLength="50" value={Height} {...bindHeight} placeholder={I18n.t("AgentPage.InputHeight")} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{I18n.t("AgentPage.InputStartTime")}</label>
                                <input class="form-control" type="date" ref={StartTimeRef} value={StartTime} {...bindStartTime} placeholder={I18n.t("AgentPage.InputStartTime")} />
                            </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <button type="button" disabled={disabled} class="btn btn-success pd-30" onClick={(e) => onAction()}>{I18n.t("AgentPage.ButtonAction")}</button>
                        </div>
                    </div >
                </div >
            </section >
        </>
    )
}